<template>
    <v-dialog
        v-model="showDialog"
        max-width="500px"
    >
        <v-card>
            <v-card-title>
                <span
                    class="text-h5"
                >{{ formTitle }}</span>
            </v-card-title>
            <v-card-subtitle>
                <span
                    class="text-h6 ml-2"
                >{{ role.attributes.display_name }}</span>
            </v-card-subtitle>

            <!-- Edited roles-->
            <v-card-text>
                <permissions-select
                    :items-selected="rolePermissions"
                    @update:permission="changeState"
                />
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                    {{ $t('common.close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from '@/plugins/axios'
import PermissionsSelect from '@/components/admin/permissions/permissionsSelect'

export default {
    components: { PermissionsSelect },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        role: {
            type: Object,
            required: true
        }
    },
    data: function () {
        return {
            urlMain: '/permissions',
            rolePermissions: []
        }
    },

    computed: {
        showDialog: {
            get() {
                return this.dialog;
            },
            set(val) {
                this.$emit('update:dialog', false)
            }
        },
        formTitle() {
            return this.$t('admin.roles.permissionsDialogTitle')
        }
        // ...mapGetters('permissions', ['getPermissions'])
    },
    /* watch: {
        dialog(val) {
            if (val) {

            }
        }
    }, */

    // Fetches posts when the component is created.
    created() {
        this.$store.dispatch('permissions/loadPermissions');
    },

    mounted() {
        this.loadUserPermissions();
    },

    methods: {
        async loadUserPermissions() {
            const perm = (await axios
                .get('rolesPermissions/' + this.role.id)).data;

            this.rolePermissions = [];

            perm.forEach(p => {
                this.rolePermissions[p.id] = true;
            });

            /* console.log('this.rolePermissions')
            console.log(this.rolePermissions) */
        },
        async changeState(status, id) {
            this.rolePermissions[id] = status;

            const perm = this.$store.getters['permissions/getPermissions'];

            const permission = perm.find(p => p.id === id).name;

            var dataSubmit = {
                roleId: this.role.id,
                permission,
                status
            };

            await axios
                .post('updateRolePermission', dataSubmit);
        },
        close() {
            this.$emit('update:dialog', false)
        }
    }
}
</script>
