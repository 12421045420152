<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title>
                    <div class="d-flex justify-space-between flex-wrap">
                        <v-btn
                            class="ma-2"
                            dark
                            color="primary"
                            @click="createUser"
                        >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t('admin.roles.add') }}
                        </v-btn>
                    </div>
                </v-card-title>
                <v-card-title>
                    {{ $t('admin.roles.title') }}
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />

                    <v-text-field
                        v-model="search"
                        :append-icon="!search ? 'mdi-magnify' : undefined"
                        clearable
                        :label="$t('common.search')"
                        single-line
                        hide-details
                    />
                </v-card-title>

                <role-create
                    v-if="dialog"
                    :dialog="dialog"
                    :item="itemSelected"
                    @update:dialog="dialog = $event"
                    @update:table="fetchRoles"
                />

                <!--                  @update:dialog="dialogPermissions = $event"-->
                <permissions-dialog
                    v-if="dialogPermissions"
                    :dialog="dialogPermissions"
                    :role="itemSelected"
                    @update:dialog="dialogPermissions = $event"
                />

                <!-- :pagination.sync="pagination" -->
                <v-data-table
                    :options.sync="options"
                    :headers="headers"
                    :footer-props="{ 'items-per-page-options': [5, 10, 30, 50, -1] }"
                    :items="items"
                    class="elevation-1"
                    :server-items-length="pagination.totalItems"
                    :sort-by="sortby"
                    :multi-sort="true"
                >
                    <template #item.id="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <template #item.attributes.name="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>
                    <template #item.attributes.display_name="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <template v-slot:item.attributes.created-at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value)" />
                    </template>
                    <template #item.attributes.updated-at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value)" />
                    </template>

                    <!--                    <template v-slot:item.role_id="{ item }">
              {{ itemsRoles.find((r) => r.id_role == item.role_id).name }}
          </template>-->

                    <template v-slot:item.actions="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    class="mr-2"

                                    v-bind="attrs"
                                    @click="editItem(item)"
                                    v-on="on"
                                >
                                    mdi-pencil
                                </v-icon>
                            </template>

                            <span>{{ $t('common.edit') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    class="mr-2"
                                    small
                                    v-bind="attrs"
                                    @click="deleteItem(item)"
                                    v-on="on"
                                >
                                    mdi-delete
                                </v-icon>
                            </template>

                            <span>{{ $t('common.delete') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    :color="item.attributes.name === 'Admin' ? 'secondary' : ''"
                                    small
                                    v-bind="attrs"
                                    @click="showDialogPermissions(item)"
                                    v-on="on"
                                >
                                    mdi-lock-open
                                </v-icon>
                            </template>

                            <span>{{ $t('common.permissions') }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                        <v-btn
                            color="primary"
                            @click="fetchRoles"
                        >
                            {{ $t('admin.common.resetData') }}
                        </v-btn>
                    </template>
                </v-data-table>
            </base-card>

            <confirm-dialog
                ref="confirm"
            />
        </v-col>
    </v-row>
</template>

<script>
import axios from '@/plugins/axios'
import RoleCreate from '@/components/admin/roles/rolesCreate'
import ConfirmDialog from '@/components/common/ConfirmDialog'
import { debounce } from "debounce";
import PermissionsDialog from '@/components/admin/roles/permissionsDialog'

// import * as dateHelper from '@/helpers/date'

export default {
    components: { RoleCreate, ConfirmDialog, PermissionsDialog },
    data: function () {
        return {
            urlMain: '/roles',
            dialog: false,
            dialogPermissions: false,
            headers: [
                {
                    text: 'Id',
                    align: 'start',
                    sortable: true,
                    value: 'id'
                },
                {
                    text: this.$t('admin.roles.name'),
                    value: 'attributes.name'
                },
                {
                    text: this.$t('admin.roles.display_name'),
                    value: 'attributes.display_name'
                },
                {
                    text: this.$t('admin.common.createdAt'),
                    value: 'attributes.created-at'
                },
                {
                    text: this.$t('admin.common.updatedAt'),
                    value: 'attributes.updated-at'
                },
                // { text: "Role", value: "role_id" },
                {
                    text: this.$t('admin.common.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            search: '',
            items: [],
            itemsRoles: [],
            itemSelected: null,
            sortby: ['id'],
            pagination: {
                totalItems: 10
            },
            options: {
                itemsPerPage: 10
            }
        }
    },
    watch: {
        options: {
            handler(newValue, oldValue) {
                this.fetchRoles(newValue)

                if (newValue.sortBy && newValue.sortBy.length > 1) {
                    this.$store.dispatch('snackbar/success', this.$t('common.multisort'))
                }
            }
        },
        search: {
            handler(newValue) {
                this.fetchRoles();
            }
        },

        dialog(val) {
            val || this.close()
        }
    },
    /* computed: {
        ...mapGetters(['role'])
    }, */

    created() {

    },

    mounted() {

    },

    methods: {
        fetchRoles: debounce(function (e) {
            this.fetchRolesFinal(e);
        }, 500),

        async fetchRolesFinal(data = this.options) {
            /* console.log('********** data ******')
        console.log(data) */

            const optionalsParams = []
            if (this.search) { optionalsParams['filter[search]'] = this.search }

            if (data?.sortBy?.length) {
                const joinSort = data.sortBy.map((value, index) =>
                    (data.sortDesc[index] ? '-' : "") + value.replace('attributes.', "")
                );

                optionalsParams.sort = joinSort.join(',')
            }

            try {
                const roles = await axios
                    .get(this.urlMain, {
                        params: {
                            'page[size]': data.itemsPerPage,
                            'page[number]': data.page,
                            ...optionalsParams
                        }
                    })
                /* console.log('*********** roles **********')
          console.log(roles) */
                this.initialize(roles.data)
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        initialize($data) {
            this.items = $data.data

            this.pagination.totalItems = $data?.meta?.page?.total ?? 0
        },
        createUser() {
            this.dialog = true;
        },
        /* setRole($data) {
          this.itemsRoles = $data;
      }, */
        editItem(item) {
            this.itemSelected = item;
            this.dialog = true;
        },
        async deleteItemConfirmed(item) {
            const urlAppend = item.id ? '/' + item.id : '';

            try {
                const result = await axios
                    .delete(this.urlMain + urlAppend);

                if (result.status === 204) {
                    this.fetchRoles();
                    // snackbar/
                    this.$store.dispatch('snackbar/success', this.$t('common.deleteOK'))
                }
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                    console.log(error.response.headers)
                }
            }
            this.$store.dispatch('loading/end')
        },
        async deleteItem(item) {
            if (
                await this.$refs.confirm.open(
                    this.$t('common.confirmTitle'),
                    this.$t('common.confirmMSGDelete')
                )
            ) {
                this.deleteItemConfirmed(item)
            }
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.itemSelected = null
            })
        },
        async showDialogPermissions(item) {
            if (item.attributes.name === 'Admin') {
                this.$store.dispatch('snackbar/success', this.$t('admin.users.adminHasAllPermissions'))
            } else {
                this.itemSelected = item;
                this.dialogPermissions = true;
            }
        }
    }
}
</script>
