<template>
    <div>
        <roles-component />
    </div>
</template>

<script>
import RolesComponent from '@/components/admin/roles/rolesList'
export default {
    name: "AdminRoles",
    components: { RolesComponent },
    data: () => ({

    })
};
</script>
